// IE polyfill
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';


import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import './normalize.css'
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <ConfigProvider locale={zh_CN}>
        <App />
    </ConfigProvider>,
    // <React.StrictMode>
    //     <App/>
    // </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
