/**
 * @author sudong.duan
 * Date: 21-12-16
 * desc: 公共方法
 */

/**
 *判断是否为空
 *@param {String}a 变量
 *@return 是否为空
 */
const isEmpty = function (a: any): boolean {
	if (a === undefined || a === 'undefined' || a === null || a === 'null' || a === '' || JSON.stringify(a) === '{}' || JSON.stringify(a) === '[]') {
		return true
	}
	return false
}

/**
 *日期格式化
 *@param {Date}time 时间
 *@param {String}format 时间格式
 *@return {String} 新格式日期
 */
const dateFormat = function (time: any, format: string): string {
	if (isEmpty(time)) {
		return ''
	}
	const t = new Date(time)
	const tf = function (i: any) {
		return (i < 10 ? '0' : '') + i
	}
	return format.replace(/YYYY|MM|DD|hh|mm|ss/g, function (a) {
		switch (a) {
			case 'YYYY':
				return tf(t.getFullYear())
			case 'MM':
				return tf(t.getMonth() + 1)
			case 'DD':
				return tf(t.getDate())
			case 'hh':
				return tf(t.getHours())
			case 'mm':
				return tf(t.getMinutes())
			case 'ss':
				return tf(t.getSeconds())
			default:
				return ''
		}
	})
}

/**
 快速排序
 @param { Array } list 待排序的数据
@param { String } key 排序的参数
 @return
 */
const sortArray = (list: any[], key: string): any[] => {
	if (list.length <= 1) {
		return list
	}
	const medianNum = list[Math.ceil(list.length / 2)][key]
	if (isEmpty(medianNum)) {
		console.error('列表项无该属性')
		return list
	}
	let preList: any[] = [], nextList: any[] = [], currList: any[] = []
	for (let i = 0; i < list.length; i++) {
		const item = list[i]
		if (Number(item[key]) < (medianNum)) {
			preList.push(item)
		} else if (item[key] > medianNum) {
			nextList.push(item)
		} else {
			currList.push(item)
		}
	}
	return sortArray(preList, key).concat(currList, sortArray(nextList, key))
}

/**
 获取本月第一天
 param
 @return { Number } 本月第一天的时间戳
 */
const getMonthFirstDay = () => {
	const year = new Date().getFullYear()
	const month = new Date().getMonth()
	return new Date(year, month)
}

/**
 节流函数
 @param {Function} callback 回调函数
 @return
 */
const throttleFnc = function (callback: Function) {
	 let isLimit: boolean = false
	 // tslint: disable 
    return function () {
        if (!isLimit) {
			isLimit = true
			// @ts-ignore #
            callback.call(this, function () {
				isLimit = false
				// @ts-ignore #
            }, ...arguments)
        }
    }
}

/**
 获取子菜单key
 @param {Function} callback 回调函数
 @return
 */
const getSubTitle = () => {
	const path = window.location.pathname, pathList = path.split('/')
	return '/'+ [pathList[1], pathList[2]].join('/')
}

/**
 数字换算 10000 -> 1Wx
 @param {String} 
 @return
 */
const replaceNumbeToW = (value: string | number): string => {
	if (value >= 10000) {
		return `${(Number(value) / 10000).toFixed(1)}W`
	} else {
		return `${value || 0}`
	}
}


/**
 日期换算 
 @param {String} 
 @return
 */
const replaceDateFormYYYYMMDD = (date: string | number, separator: string | string[] = ''): string => {
	date = `${date}`
	if (!date) {
		return ''
	} else {
		if (Array.isArray(separator)) {
			return `${date.substring(0, 4)}${separator[0] || ''}${date.substring(4, 6)}${separator[1] || ''}${date.substring(6, 8)}${separator[2] || ''}`
		} else {
			return `${date.substring(0, 4)}${separator}${date.substring(4, 6)}${separator}${date.substring(6, 8)}`
		}
	}
	
}

/**
 格式化数字 
 @param {Number} val 待格式化的数字
 @return String
 */
const numberFormat = (val: number) => {
	return val >= 10 ? `${val}` : `0${val}`
}


export {
	isEmpty,
	dateFormat,
	sortArray,
	getMonthFirstDay,
	throttleFnc,
	getSubTitle,
	replaceNumbeToW,
	replaceDateFormYYYYMMDD,
	numberFormat
}