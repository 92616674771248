
// 官网页面
import Official from "@/pages/sys/official";







interface router {
    path: string,
    component: any,
    exact?: boolean,
    children?: Array<router>
}

const routers: Array<router> = [
    {
        path: '/',
        component: Official,
        exact: true
    }
]
export default routers