/**
 * @author sudong.duan
 * Date: 21-12-16
 * desc: 公共过滤方法
 */

/**
 *过滤纯数字
 *@param {String} str 待过滤的字符串
 *@return { String } 过滤后的字符串
 */
const filterInt = (str: string) => {
	return str.replace(/[^\d]/g, '')
}

/**
 *过滤纯数字
 *@param {String} str 待过滤的字符串
 *@return { String } 过滤后的字符串
 */
const filterDecimal = (str: string, decimalLen: number) => {
	//过滤非数字和.
	let result = str.toString().replace(/[^\d/.]/g, '')
	//去除多余的.
    result = result.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")   
    let regStr = '^(\\-)*(\\d+)\\.('
    for (let i = 0; i < decimalLen; i++) {
        regStr += '\\d'
    }
    regStr += ').*$'
	const decimalReg = new RegExp(regStr)
	//小数点后最多 decimalLen 位
    return result.replace(decimalReg, '$1$2.$3')  
}



export {
	filterInt,
	filterDecimal
}