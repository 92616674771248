import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import routers from "./router";
import ErrorPage from "./pages/error/404";
import { getUserInfoStorage } from '@/utils/storage'
//import { message } from 'antd'

class App extends Component<any, any>{
    render(): React.ReactNode {
        // message.config({
        //     maxCount: 2
        // })
        return (
            <BrowserRouter>
                {/* switch只会渲染匹配到的第一个子元素 */}
                <Switch>
                    {
                        routers.map((router, index) => {
                            return (
                                <Route
                                    exact={router.exact}
                                    key={index}
                                    path={router.path}
                                    render={(props) => {
                                        return (
                                            <router.component {...props}>
                                                {
                                                    router.children?.map((item, itemIndex) => {
                                                        return (
                                                            getUserInfoStorage().token ?
                                                                <Route
                                                                    exact={item.exact}
                                                                    key={itemIndex}
                                                                    path={item.path}
                                                                    component={item.component}
                                                                /> : <Redirect to={{
                                                                    pathname: '/',
                                                                    key: '/',
                                                                    state: { from: props.location }
                                                                }} />
                                                        )
                                                    })
                                                }
                                            </router.component>
                                        )
                                    }}
                                />
                            )
                        })
                    }
                    <Route path="*">
                        <ErrorPage />
                    </Route>
                </Switch>
            </BrowserRouter>
        )
    }
}
export default App
