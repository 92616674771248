/**
 * @author sudong.duan
 * Date: 21-12-16
 * desc: 公共保存缓存数据方法
 */

/**
 *获取手机号缓存
 *@param 
 *@return { String } 手机号
 */
const getPhoneStorage = (): string => {
	return localStorage.getItem('phone') || ''
}

/**
 *保存手机号缓存
 *@param { String } phone 手机号
 *@return 
 */
const setPhoneStorage = (phone: string) => {
	localStorage.setItem('phone', phone)
}

/**
 *保存token
 *@param { String } token 手机号
 *@return 
 */
 const setTokenStorage = (token: string) => {
	localStorage.setItem('token', token)
}

/**
 *获取token缓存
 *@param 
 *@return { String } 手机号
 */
const getTokenStorage = (): string => {
	return localStorage.getItem('token') || ''
}

/**
 *删除用户Token
 *@param 
 *@return
 */
const removeTokenStorage = () => {
	localStorage.removeItem('userInfo')
}

/**
 *保存用户信息
 *@param { String } userInfo 机构数据
 *@return 
 */
 const setUserInfoToken = (userInfo: UserInfo) => {
	localStorage.setItem('userInfo', JSON.stringify(userInfo))
}

/**
 *获取用户信息
 *@param 
 *@return { Object } 机构数据
 */
const getUserInfoStorage = (): UserInfo => {
	return JSON.parse(localStorage.getItem('userInfo') || '{}')
}

/**
 *删除用户信息
 *@param 
 *@return
 */
const removeUserInfoStorage = () => {
	localStorage.removeItem('userInfo')
}


export {
	setPhoneStorage,
	getPhoneStorage,
	setTokenStorage,
	getTokenStorage,
	removeTokenStorage,
	setUserInfoToken,
	getUserInfoStorage,
	removeUserInfoStorage
}