/**
 * @author sudong.duan
 * Date: 21-12-16
 * desc: axios请求方法
 */

import axios, { AxiosResponse } from 'axios'
import { isEmpty } from './common'
import { message } from 'antd'
//import { router } from '../router'
import { getUserInfoStorage, removeUserInfoStorage, removeTokenStorage } from './storage'


//设置请求头
axios.defaults.headers.post['Content-Type'] = 'application/json'
//axios.defaults.headers.post['requestClient'] = '3'

let baseUrl = ''
if (process.env.REACT_APP_ENV === 'development') {
    baseUrl = 'http://dev.backend.7nc.top'
} else if (process.env.REACT_APP_ENV === 'pre') {
    baseUrl = 'http://dev.backend.7nc.top'
} else if (process.env.REACT_APP_ENV === 'pre-release') {
    baseUrl = 'https://dev.syn.oslink.cn'
} else if (process.env.REACT_APP_ENV === 'production') {
    baseUrl = 'https://backend.7nc.top'
}


//请求前拦截
axios.interceptors.request.use(
    (config: any) => {
        if (config.url.indexOf('http://') === -1 && config.url.indexOf('https://') === -1) {
            config.url = baseUrl + config.url
        }
        const userInfo = getUserInfoStorage()
        config.headers['token'] = userInfo.token  
        config.headers['userId'] = userInfo.userId
        if (!config.responseType) {
            config.transformResponse = [(data: any) => {
                return JSON.parse(data)
            }]
        }
        return config
    },
    (error: any) => {
        return error
    }
)

//请求返回拦截
axios.interceptors.response.use(
    (response: any) => {
        try {
            if (response) {
                const result = response
                return result
            }
        } catch (err) {
            //window.$message.error(err)
            return {}
        }
    },
    (error: any) => {
        if (error.response) {
            return error.response
        }
    }
)

enum responseCode {
    /**
     * 请求成功
     */
    success = 0,
    resultSuccess = 1,
    /**
     * token失效
     */
    tokenFailure = 201
}

interface paramsConfig<T> {
    /**
     * http请求链接
     */
    url: string,
    /**
     * http请求体
     */
    data: T,
    /**
     * 是否默认跳转登录页
     */
    isDefault?: boolean
    /**
     * 是否隐藏提示
     */
    hideNote?: boolean
    /**
     * 请求方法
     */
    method?: 'GET' | 'POST'
    /**
     * 请求头
     */
    headers?: any
    /**
     * 响应类型
     */
    responseType?: 'blob' | 'arraybuffer' & string
}

interface responseConfig<T> {
    /**
     * 状态码
     */
    Code: number,
    /**
     * 数据
     */
    Data: T,
    data: T,
    /**
     * 提示信息
     */
    Msg: string
    /**
     * 
     */
    result: number
}

/**
 * 公共响应体
 */
interface response<T> {
    /**
     * 状态码
     */
    Code: number,
    /**
     * 数据
     */
    Data: T,
    /**
     * 提示信息
     */
    Msg: string
    /**
     * 
     */
    result: number
}

export const requestApi = <requestDataConfig, responseDataConfig>(params: paramsConfig<requestDataConfig>): Promise<responseConfig<responseDataConfig>> => {
    return new Promise((resolve, reject) => {
        if (params.method === 'GET') {
            axios.get(params.url, { params: params.data })
                .then((res: AxiosResponse<response<responseDataConfig>>) => {
                    if (isEmpty(res) || isEmpty(res.data)) {
                        message.error('网络请求失败，请稍后再试')
                        return
                    }
                    if (res.data.Code === responseCode.success || res.data.result === responseCode.resultSuccess) {
                        resolve(res.data as any)
                    } else if (res.data.Code === 610) {
                        message.error(res.data.Msg)
                        removeUserInfoStorage()
                        removeTokenStorage()
                        window.location.href = '/'
                    } else {
                        if (!params.hideNote) {
                            message.error(res.data.Msg)
                        }
                        reject(res.data)
                    }
                })
        } else {
            axios.post(params.url, params.data, {
                responseType: params.responseType,
                headers: params.headers,
                
            })
                .then((res: AxiosResponse<response<responseDataConfig>>) => {
                    if (params.responseType) {
                        resolve(res as any)
                    } else {
                        if (isEmpty(res) || isEmpty(res.data)) {
                            message.error('网络请求失败，请稍后再试')
                            return
                        }
                        if (res.data.Code === responseCode.success || res.data.result === responseCode.resultSuccess) {
                            resolve(res.data as any)
                        } else if (res.data.Code === 610) {
                            message.error(res.data.Msg)
                            removeUserInfoStorage()
                            removeTokenStorage()
                            window.location.href = '/'
                        }  else {
                            if (!params.hideNote) {
                                message.error(res.data.Msg)
                            }
                            reject(res.data)
                        }
                    }

                })
        }

    })
}

//export { axios }