import about1Image from '@/assets/sys/official/platform_advantages_icon1.png'
import about2Image from '@/assets/sys/official/platform_advantages_icon2.png'
import about3Image from '@/assets/sys/official/platform_advantages_icon3.png'
import about4Image from '@/assets/sys/official/platform_advantages_icon4.png'
import toPlat1Image from '@/assets/sys/official/support_platform_drainage1.png'
import toPlat2Image from '@/assets/sys/official/support_platform_drainage2.png'
import toPlat3Image from '@/assets/sys/official/support_platform_drainage3.png'
import toPlat4Image from '@/assets/sys/official/support_platform_drainage4.png'
import fromPlat1Image from '@/assets/sys/official/support_platform_mall1.png'
import fromPlat2Image from '@/assets/sys/official/support_platform_mall2.png'
import fromPlat3Image from '@/assets/sys/official/support_platform_mall3.png'
import fromPlat4Image from '@/assets/sys/official/support_platform_mall4.png'
import marke1Image from '@/assets/sys/official/marke_1.png'
import marke2Image from '@/assets/sys/official/marke_2.png'
import marke3Image from '@/assets/sys/official/marke_3.png'
import marke4Image from '@/assets/sys/official/marke_4.png'
import marke5Image from '@/assets/sys/official/marke_5.png'
import marke1WapImage from '@/assets/sys/official/wap_platform_introduction_pic1.png'
import marke2WapImage from '@/assets/sys/official/wap_platform_introduction_pic2.png'
import marke3WapImage from '@/assets/sys/official/wap_platform_introduction_pic3.png'
import marke4WapImage from '@/assets/sys/official/wap_platform_introduction_pic4.png'
import marke5WapImage from '@/assets/sys/official/wap_platform_introduction_pic5.png'

import { validPhone } from '@/utils/reg'

const tabbarList = [
	{
		label: '首页',
		href: '#',
		warpHerf: '#'
	},
	{
		label: '平台优势',
		href: '#about',
		warpHerf: '#about-wap'
	},
	{
		label: '平台介绍',
		href: '#introduction',
		warpHerf: '#introduction-wap'
	},
	{
		label: '支持的平台',
		href: '#support',
		warpHerf: '#support-wap'
	},
	{
		label: '行业提升介绍',
		href: '#promote',
		warpHerf: '#promote-wap'
	},
	{
		label: '商务合作',
		href: '#cooperation',
		warpHerf: '#cooperation-wap'
	}
]

const aboutList = [
	{
		title: "引流平台广",
		subTitle: "支持头条、抖音、西瓜、快手、微信视频号、腾讯新闻、qq、爱奇艺等等",
		img: about1Image
	},
	{
		title: "电商平台全",
		subTitle: "支持各媒体使用一跳或者二跳方式跳转至天猫、淘宝、京东、拼多多",
		img: about2Image
	},
	{
		title: "回传链路稳定",
		subTitle: "订单同步与回传链路技术高效稳定",
		img: about3Image
	},
	{
		title: "报表数据智能",
		subTitle: "跨平台多维度的数据隔离与汇总分钟级引流计划roi数据同步",
		img: about4Image
	},
]


const productTabbarList = [
	{
		label: '支持的引流平台'
	},
	{
		label: '支持的电商平台'
	}
]

const supportRenderList = [
	[
		{
			img: toPlat1Image,
			width: "2.06rem",
			height: "0.55rem"
		},
		{
			img: toPlat2Image,
			width: "2.06rem",
			height: ".55rem"
		},
		{
			img: toPlat3Image,
			width: "1.4rem",
			height: ".48rem"
		},
		{
			img: toPlat4Image,
			width: "2.11rem",
			height: ".34rem"
		}
	],
	[
		{
			img: fromPlat1Image,
			width: "1.1rem",
			height: ".7rem"
		},
		{
			img: fromPlat2Image,
			width: "1.1rem",
			height: ".68rem"
		},
		{
			img: fromPlat3Image,
			width: "2.16rem",
			height: ".7rem"
		},
		{
			img: fromPlat4Image,
			width: "1.68rem",
			height: ".6rem"
		}
	]
]

const toPlatList = [
	{
		img: toPlat1Image,
		width: "206px",
		height: "55px"
	},
	{
		img: toPlat2Image,
		width: "206px",
		height: "55px"
	},
	{
		img: toPlat3Image,
		width: "140px",
		height: "48px"
	},
	{
		img: toPlat4Image,
		width: "211px",
		height: "34px"
	}
]

const fromPlatList = [
	{
		img: fromPlat1Image,
		width: "110px",
		height: "70px"
	},
	{
		img: fromPlat2Image,
		width: "110px",
		height: "68px"
	},
	{
		img: fromPlat3Image,
		width: "216px",
		height: "70px"
	},
	{
		img: fromPlat4Image,
		width: "168px",
		height: "60px"
	}
]

const industryOptions = ["大健康", "美妆", "服装", "个护", "食品", "家清", "日化", "其他"]

const toPlatOptions = ["淘宝", "天猫", "京东", "拼多多"]

const fromPlatOptions = ["巨量引擎", "磁力引擎", "广点通", "爱奇艺"]



const markeRenderList = [
	{
		image: marke1Image,
		title: '客户管理',
		subTitle: '代理商可以一站式管理多个商家客户'
	},
	{
		image: marke2Image,
		title: '商品管理',
		subTitle: '多角色协同在线管理cid投放商品的推广规则和推广链接'
	},
	{
		image: marke3Image,
		title: '运营数据',
		subTitle: '实时查看各账户、计划级的消耗和roi数据以及订单详细信息'
	},
	{
		image: marke4Image,
		title: '数据总览',
		subTitle: '今日实时的所有关联汇总数据以及历史交易数据折线走势'
	},
	{
		image: marke5Image,
		title: '回传速率',
		subTitle: '常规情况最长5分钟同步订单数据而后秒级回传至引流平台'
	}
]

const markeRenderWapList = [
	{
		image: marke1WapImage,
		title: '客户管理',
		subTitle: '代理商可以一站式管理多个商家客户'
	},
	{
		image: marke2WapImage,
		title: '商品管理',
		subTitle: '多角色协同在线管理cid投放商品的推广规则和推广链接'
	},
	{
		image: marke3WapImage,
		title: '运营数据',
		subTitle: '实时查看各账户、计划级的消耗和roi数据以及订单详细信息'
	},
	{
		image: marke4WapImage,
		title: '数据总览',
		subTitle: '今日实时的所有关联汇总数据以及历史交易数据折线走势'
	},
	{
		image: marke5WapImage,
		title: '回传速率',
		subTitle: '常规情况最长5分钟同步订单数据而后秒级回传至引流平台'
	}
]


const formRenderList = [
	{
		label: '公司名称',
		value: 'companyName',
		placeholder: '请输入'
	},
	{
		label: '联系人',
		value: 'contact',
		placeholder: '请输入'
	},
	{
		label: '联系电话',
		value: 'phone',
		placeholder: '请输入',
		vaildFnc: validPhone
	},
	{
		label: '主营行业',
		value: 'mainBusiness',
		placeholder: '请选择'
	},
	{
		label: '电商平台',
		value: 'goodsPlatName',
		placeholder: '请选择'
	},
	{
		label: '主投平台',
		value: 'adPlatName',
		placeholder: '请选择'
	}
]


export {
	tabbarList,
	aboutList,
	toPlatList,
	fromPlatList,
	supportRenderList,
	industryOptions,
	toPlatOptions,
	fromPlatOptions,
	productTabbarList,
	markeRenderList,
	markeRenderWapList,
	formRenderList
}