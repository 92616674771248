import { requestApi } from '@/utils/axios'

/**
 * 调用登录接口
 * @param params 
 * @returns 
 */
 const requestSubmitBusinessInfo = async (params: any) => {
	return await requestApi<any, any>({
		url: `/api/thirdPlat/dataUnicome/add`,
		data: {
			...params
		},
	})
}

export {
	requestSubmitBusinessInfo
}
