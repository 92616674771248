import React, { MouseEvent } from 'react';
import './index.scss'
import { OfficialProps, OfficialState } from './data'
import { requestSubmitBusinessInfo } from './service'
import { tabbarList, aboutList, toPlatList, fromPlatList, supportRenderList, industryOptions, toPlatOptions, fromPlatOptions, productTabbarList, markeRenderList, markeRenderWapList, formRenderList, } from './config'
import { isEmpty } from '@/utils/common';
import { filterInt } from '@/utils/filters'
import { Input, Form, Row, Button, Checkbox, Modal, Drawer } from 'antd';
import logoBottomImage from '@/assets/sys/official/bottom_logo.png'
import promoteArrowImage from '@/assets/sys/official/industry_promotion_arrow.png'
import banner_maskImage from '@/assets/sys/official/banner_mask.png'
import productBgImage from '@/assets/sys/official/product_bg.png'
import notificationSuccessImage from '@/assets/sys/official/notification_success.png'
import notificationFailImage from '@/assets/sys/official/notification_fail.png'
import qr_code_1Image from '@/assets/sys/official/qr_code_1.png'
import qr_code_2Image from '@/assets/sys/official/qr_code_2.png'
import closeWapImage from '@/assets/sys/official/wap_close.png'
import Item from 'antd/lib/list/Item';


export default class Official extends React.Component<OfficialProps, OfficialState> {

    constructor(props: OfficialProps = {}) {
        super(props);
        this.state = {
            slideLeft: 0,
            scrollTop: 0,
            activeTabbar: 0,
            activeSwiper: 0,
            activeProductTabbar: 0,
            formData: {},
            formErrorData: {},
            isLoading: false,
            isSuccess: false,
            isShowMenu: false,
            isFail: false,
            isClick: false,
            productPCIndex: 0,
            productAPPIndex: 0
        }
    }

    swiper = null

    caseSwiper = null

    

    heightList =  [1147, 1830, 2400, 3073, 3675, 9999]
    heightWapList = [225, 798, 2029, 2403, 2854, 3594, 9999]

    componentDidMount() {
        const that = this
        // @ts-ignore #
        this.swiper = new Swiper(".mySwiper", {
            effect: "coverflow",
            slidesPerView: 'auto',
            initialSlide: that.state.activeSwiper,
            loop: true,
            autoplay: true,
            noSwiping: true,
            grabCursor: true,
            slideToClickedSlide: true,
            centeredSlides: true,
            coverflowEffect: {
                rotate: 0,
                stretch: 10,
                slideShadows: false,
            },
            pagination: {
                el: ".swiper-pagination",
            },
            on: {
                slideChange: function (activeIndex: any) {
                    that.setState({
                        // @ts-ignore #
                        activeSwiper: (this.activeIndex % swiperList.length) || 0
                    })
                    //console.log(activeIndex
                },
            },
        });
        // @ts-ignore #
        this.caseSwiper = new Swiper(".case-swiper", {
            loop: true,
            autoplay: true,
            //autoplay: true
            // initialSlide: that.state.activeSwiper,
            // loop: true,
            // grabCursor: true,
            // slideToClickedSlide: true,
            // centeredSlides: true,
            // pagination: {
            //     el: ".swiper-pagination",
            // }
        });

        const windowWidth = document.documentElement.clientWidth 

        if (windowWidth < 768) {
            this.heightWapList = this.heightWapList.map((item) => {
                return item * windowWidth / 375
            })
        }

        window.onresize = () => {
            this.heightWapList = this.heightWapList.map((item) => {
                return item * document.documentElement.clientWidth  / 375
            })
        }

        window.onscroll = (event) => {
            const scrollTop = document.documentElement.scrollTop
            this.setState({
                scrollTop: scrollTop
            })
            if (!this.state.isClick) {
                let activeTabbar = 0, windowHeight = document.documentElement.clientHeight
                const heightList = windowWidth >= 768 ? this.heightList : this.heightWapList
                const datumHeight = windowWidth >= 768 ? windowHeight / 3 * 2 : 90
                for (let i = 0; i < heightList.length; i++) {
                    if (scrollTop < heightList[i] - datumHeight) {
                        activeTabbar = i
                        break
                    }
                }
                
                const element: any = this.refs[`tabbarItem-${activeTabbar}`]
                const childrenLeft = element.offsetLeft, childrenWidth = element.offsetWidth
                this.setState({
                    activeTabbar: activeTabbar,
                    slideLeft: childrenLeft + (childrenWidth - 30) / 2,
                })
            }
        }
    }

    // 处理点击移动端抽屉内的tabbar
    handleDrawerClick(tabbarItem:any, tabbarKey: number) {
        this.setState({
            activeTabbar: tabbarKey,
            isShowMenu: false
        })
    }


    // 处理点击tabbar
    handleClick(event: MouseEvent, tabbarKey: number) {
        this.setState({
            isClick: true
        }, () => {
            const element: any = event.target
            const childrenLeft = element.offsetLeft, childrenWidth = element.offsetWidth
            this.setState({
                slideLeft: childrenLeft + (childrenWidth - 30) / 2,
                activeTabbar: tabbarKey
            }, () => {
                setTimeout(() => {
                    this.setState({
                        isClick: false
                    })
                }, 800)
            })
        })
    }

    // 处理点击swiper上一个事件
    handleSwiperPrev() {
        if (this.swiper) {
            (this.swiper as any).slidePrev()
        }

    }

    // 处理优质案例swiper上一个事件
    handleOfficialPrev() {
        if (this.caseSwiper) {
            (this.caseSwiper as any).slidePrev()
        }
    }

    // 处理点击swiper下一个事件
    handleSwiperNext() {
        if (this.swiper) {
            (this.swiper as any).slideNext()
        }
    }

    // 处理优质案例swiper下一个事件
    handleOfficialNext() {
        if (this.caseSwiper) {
            (this.caseSwiper as any).slideNext()
        }
    }

    // 处理点击swiper事件
    handleClickSwiper(swiperKey: number) {
        if (this.swiper) {
            (this.swiper as any).slideTo(swiperKey)
        }
    }

    // 处理产品介绍点击tabbar事件
    handleActiveProductTabbar(tabbarKey: number) {
        this.setState({
            activeProductTabbar: tabbarKey
        })
    }

    // 处理鼠标移入app事件
    handleMouseMovePhone(productKey: number) {
        this.setState({
            productAPPIndex: productKey
        })
    }

    // 处理鼠标移入pc事件
    handleMouseMovePC(productKey: number) {
        this.setState({
            productPCIndex: productKey
        })
    }

    // 处理表单内容变化时触发
    handleChange(value: any, type: string, otherData?: any) {
        console.log(value)
        const formData = { ...this.state.formData }, formErrorData = { ...this.state.formErrorData }
        if (type === 'mobile') {
            value = filterInt(value)
        }
        formData[type] = value
        formErrorData[type] = ''
        this.setState({
            formData: formData,
            formErrorData: formErrorData
        })
    }

    // 处理表单提交
    handleSubmit() {
        const formErrorData: any = {}, formData = { ...this.state.formData }
        for (let i = 0; i < formRenderList.length; i++) {
            const formRenderItem = formRenderList[i]
            if (isEmpty(formData[formRenderItem.value])) {
                formErrorData[formRenderItem.value] = `${formRenderItem.placeholder}${formRenderItem.label}`
            } else {
                if (formRenderItem.vaildFnc && typeof formRenderItem.vaildFnc === 'function') {
                    if (!formRenderItem.vaildFnc(formData[formRenderItem.value])) {
                        formErrorData[formRenderItem.value] = `${formRenderItem.placeholder}正确的${formRenderItem.label}`
                    }
                }
            }
        }
        if (isEmpty(formErrorData)) {
            this.setState({
                isLoading: true
            })
            requestSubmitBusinessInfo({
                adPlatName: formData.adPlatName.join(';'),
                companyName: formData.companyName,
                contact: formData.contact,
                goodsPlatName: formData.goodsPlatName.join(';'),
                mainBusiness: formData.mainBusiness.join(';'),
                phone: formData.phone
            }).then(() => {
                //message.success('提交成功')
                this.setState({
                    formData: {},
                    isLoading: false,
                    isSuccess: true
                })
            }).catch(() => {
                this.setState({
                    isLoading: false,
                    isFail: true
                })
            })
        } else {
            this.setState({
                formErrorData: formErrorData
            })
        }

    }


    render() {
        return (
            <div className="official">
                {/* <div className='official-pc'></div> */}
                <div className='official-navbar official-navbar-next' style={{ opacity: `${this.state.scrollTop / 70}` }}>
                    <div className='official-navbar-logo' ></div>
                    <div className='official-navbar-tabbar'>
                        {
                            tabbarList.map((tabbarItem, tabbarKey) => {
                                return <a className={`${this.state.activeTabbar === tabbarKey ? 'tabItem-active' : 'tabItem'}`} ref={`tabbarItem-${tabbarKey}`} href={tabbarItem.href} onClick={(event) => { this.handleClick(event, tabbarKey) }} key={tabbarKey}>{tabbarItem.label}</a>
                            })
                        }
                        <div style={{ transform: `translateX(${this.state.slideLeft}px)` }} className='tabbar-slide tabbar-slide-next'></div>
                    </div>
                    <div className='official-navbar-menu' onClick={()=> { this.setState({ isShowMenu: true }) }}></div>
                </div>

                <div className='official-navbar official-navbar-up' style={{ opacity: `${1 - this.state.scrollTop / 70 < 0 ? 0 : 1 - this.state.scrollTop / 70}` }}>
                    <div className='official-navbar-logo'></div>
                    <div className='official-navbar-tabbar'>
                        {
                            tabbarList.map((tabbarItem, tabbarKey) => {
                                return <a className='tabItem-top-up' ref={`tabbarItem-${tabbarKey}`} href={tabbarItem.href} onClick={(event) => { this.handleClick(event, tabbarKey) }} key={tabbarKey}>{tabbarItem.label}</a>
                            })
                        }
                        <div style={{ transform: `translateX(${this.state.slideLeft}px)` }} className='tabbar-slide tabbar-slide-up'></div>
                    </div>
                    <div className='official-navbar-menu' onClick={()=> { this.setState({ isShowMenu: true }) }}></div>
                </div>
                <div className='official-header'>
                    <span className='official-header-title'>助 力 商 家 提 升 R O I</span>
                    <span className='official-header-subTitle'>数通 Data Unicom</span>
                    {/* <img className='official-header-img' src={bannerImage} alt="" /> */}
                    <img className='official-header-mask' src={banner_maskImage} alt="" />
                </div>

                <div className='official-about' id='about'>
                    <div className='official-wap-anchor' id='about-wap'></div>
                    {/* <div className='watermark'>ABOUT</div> */}
                    <div className="title">平台优势</div>
                    <div className='content'>
                        <div className='aboutList'>
                            {
                                aboutList.map((aboutItem, aboutKey) => {
                                    return <div className='aboutItem' key={aboutKey}>
                                        <img className='aboutItem-image' src={aboutItem.img} alt="" />
                                        <div className='aboutItem-title'>{aboutItem.title}</div>
                                        <div className='aboutItem-subTitle'>{aboutItem.subTitle}</div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className='official-marke' id='introduction'>
                    {/* <div className='watermark'>MARKETING</div> */}
                    <div className='official-wap-anchor' id='introduction-wap'></div>
                    <div className='title'>平台介绍</div>
                    <div className='official-marke-list'>
                        {
                            markeRenderList.map((markItem, markKey) => {
                                return <div className='official-marke-item' key={markKey}>
                                    <img className='official-marke-item-img' src={markItem.image} alt="" />
                                    <div className='official-marke-item-content'>
                                        <div className='official-marke-item-title'>{markItem.title}</div>
                                        <div className='official-marke-item-subTitle'>{markItem.subTitle}</div>
                                    </div>
                                    <div className='official-marke-item-overlay'></div>

                                </div>
                            })
                        }
                    </div>

                    <div className='official-markeWap-list'>
                        {
                            markeRenderWapList.map((markItem, markKey) => {
                                return <div className='official-marke-item' key={markKey}>
                                    <img className='official-marke-item-img' src={markItem.image} alt="" />
                                    <div className='official-marke-item-title'>{markItem.title}</div>
                                    <div className='official-marke-item-subTitle'>{markItem.subTitle}</div>
                                    <div className='official-marke-item-overlay'></div>

                                </div>
                            })
                        }
                    </div>
                </div>

                <div className='official-product' id='support'>
                    {/* <div className='watermark'>INTRODUCTION</div> */}
                    <div className='official-wap-anchor' id='support-wap'></div>
                    <div className='title'>支持的平台</div>
                    <div className='official-product-bg'>
                        <img src={productBgImage} alt="" />
                    </div>
                    <div className='official-product-tabbar'>
                        <div className='official-product-tabba-slide' style={{ transform: `translateX(${this.state.activeProductTabbar * 190 + 4}px)` }}></div>
                        <div className='official-product-tabbar-wap-slide' style={{ transform: `translateX(${this.state.activeProductTabbar * 2.2}rem)` }}></div>
                        {
                            productTabbarList.map((tabbarItem, tabbarKey) => {
                                return <div key={tabbarKey} className='official-product-tabbar-item' onClick={() => { this.handleActiveProductTabbar(tabbarKey) }}>{tabbarItem.label}</div>
                            })
                        }
                    </div>

                    <div className='official-product-swiper'>
                        <div className='official-product-swiper-list' style={{ transform: `translateX(${this.state.activeProductTabbar * -1200}px)` }}>
                            <div className='official-product-swiperItem' >
                                {
                                    toPlatList.map((platItem, platKey) => {
                                        return <div className='official-product-swiperItem-item' key={platKey}>
                                            <img style={{ width: platItem.width, height: platItem.height }} src={platItem.img} alt="" />
                                        </div>
                                    })
                                }
                            </div>
                            <div className='official-product-swiperItem'>
                                {
                                    fromPlatList.map((platItem, platKey) => {
                                        return <div className='official-product-swiperItem-item' key={platKey}>
                                            <img style={{ width: platItem.width, height: platItem.height }} src={platItem.img} alt="" />
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className='official-product-wap-swiper'>
                        {
                            supportRenderList.map((supportItem, supportKey) => {
                                return this.state.activeProductTabbar === supportKey ? <div className='official-product-wap-swiperItem' key={supportKey}>
                                    {
                                        supportItem.map((platItem, platKey) => {
                                            return <div className={`official-product-swiperItem-item ${platKey % 2 ? '' : 'official-product-swiperItem-item-left'}`} key={platKey}>
                                                <img style={{ width: platItem.width, height: platItem.height }} src={platItem.img} alt="" />
                                            </div>
                                        })
                                    }
                                </div> : <div key={supportKey}></div>
                            })
                        }
                    </div>
                </div>

                <div className='official-promote' id='promote'>
                    {/* <div className='watermark'>ABOUT</div> */}
                    <div className='official-wap-anchor' id='promote-wap'></div>
                    <div className="title">行业提升介绍</div>
                    <div className='content'>
                        <div className='official-promote-row1'>
                            <div className='official-promote-item'>
                                <div className='official-promote-item-name'>美妆类</div>
                                <div className='official-promote-item-subTitle'>接入ROI后</div>
                                <img className='official-promote-item-img' src={promoteArrowImage} alt="" />
                                <div className='official-promote-item-value'>
                                    <span className='official-promote-item-num'>36</span>
                                    <span className='official-promote-item-unit'>%</span>
                                </div>
                            </div>
                            <div className='official-promote-item'>
                                <div className='official-promote-item-name'>食品类</div>
                                <div className='official-promote-item-subTitle'>接入ROI后</div>
                                <img className='official-promote-item-img' src={promoteArrowImage} alt="" />
                                <div className='official-promote-item-value'>
                                    <span className='official-promote-item-num'>40</span>
                                    <span className='official-promote-item-unit'>%</span>
                                </div>
                            </div>
                        </div>

                        <div className='official-promote-row2'>
                            <div className='official-promote-item'>
                                <div className='official-promote-item-name'>健康类</div>
                                <div className='official-promote-item-subTitle'>接入ROI后</div>
                                <img className='official-promote-item-img' src={promoteArrowImage} alt="" />
                                <div className='official-promote-item-value'>
                                    <span className='official-promote-item-num'>109</span>
                                    <span className='official-promote-item-unit'>%</span>
                                </div>
                            </div>
                            <div className='official-promote-item'>
                                <div className='official-promote-item-name'>日化类</div>
                                <div className='official-promote-item-subTitle'>接入ROI后</div>
                                <img className='official-promote-item-img' src={promoteArrowImage} alt="" />
                                <div className='official-promote-item-value'>
                                    <span className='official-promote-item-num'>80</span>
                                    <span className='official-promote-item-unit'>%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='official-cooperation' id='cooperation'>
                    {/* <div className='watermark'>COOPERATION</div> */}
                    <div className='official-wap-anchor' id='cooperation-wap'></div>
                    <div className='title'>商务合作</div>
                    <div className='official-cooperation-content'>
                        <div className='official-cooperation-form'>
                            <Form name="horizontal_login" layout="inline" form={this.state.formData}>
                                <Row >
                                    <Form.Item label="公司名称" required className='official-cooperation-form-input'>
                                        <Input size="large" value={this.state.formData.companyName} onChange={(event) => { this.handleChange(event.target.value, 'companyName') }} maxLength={100} />
                                        <span className='official-cooperation-form-error'>{this.state.formErrorData.companyName}</span>
                                    </Form.Item>
                                </Row>
                                <Row>
                                    <Form.Item label="联系人" required className='official-cooperation-form-input'>
                                        <Input size="large" value={this.state.formData.contact} onChange={(event: any) => { this.handleChange(event.target.value, 'contact') }} maxLength={50} />
                                        <span className='official-cooperation-form-error'>{this.state.formErrorData.contact}</span>
                                    </Form.Item>
                                </Row>
                                <Row>
                                    <Form.Item label="联系电话" required className='official-cooperation-form-input'>
                                        <Input size="large" maxLength={11} value={this.state.formData.phone} onChange={(event: any) => { this.handleChange(event.target.value, 'phone') }} />
                                        <span className='official-cooperation-form-error'>{this.state.formErrorData.phone}</span>
                                    </Form.Item>
                                </Row>
                                <Row>
                                    <Form.Item label="主营行业" required className='official-cooperation-form-checkbox'>
                                        <Checkbox.Group options={industryOptions} value={this.state.formData.mainBusiness} onChange={(value: any) => { this.handleChange(value, 'mainBusiness') }} />
                                        <span className='official-cooperation-form-error'>{this.state.formErrorData.mainBusiness}</span>
                                    </Form.Item>
                                </Row>
                                <Row>
                                    <Form.Item label="电商平台" required className='official-cooperation-form-checkbox'>
                                        <Checkbox.Group options={toPlatOptions} value={this.state.formData.goodsPlatName} onChange={(value: any) => { this.handleChange(value, 'goodsPlatName') }} />
                                        <span className='official-cooperation-form-error'>{this.state.formErrorData.goodsPlatName}</span>
                                    </Form.Item>
                                </Row>
                                <Row>
                                    <Form.Item label="主投平台" required className='official-cooperation-form-checkbox'>
                                        <Checkbox.Group options={fromPlatOptions} value={this.state.formData.adPlatName} onChange={(value: any) => { this.handleChange(value, 'adPlatName') }} />
                                        <span className='official-cooperation-form-error'>{this.state.formErrorData.adPlatName}</span>
                                    </Form.Item>
                                </Row>


                                <Row>
                                    <Button loading={this.state.isLoading} className='official-cooperation-submit' onClick={() => { this.handleSubmit() }}>预约咨询</Button>
                                </Row>

                            </Form>
                        </div>
                        <div className='official-cooperation-desc'>
                            <div className='official-cooperation-desc-name'>数通Data Unicom</div>
                            <div className='official-cooperation-desc-title'>助力商家提升ROI</div>
                            <div className='official-cooperation-desc-prompt1'>请填写真实信息</div>
                            <div className='official-cooperation-desc-prompt2'>以便我们与您取得联系</div>
                        </div>

                    </div>
                </div>
                <footer className='official-footer'>
                    <div className='official-footer-content'>
                        <span className='official-footer-phone'>联系电话：010-80829671</span>
                        {/* <span className='official-footer-email'>联系邮箱：douzhuan@douzhuan.cn</span> */}
                        <span className='official-footer-address'>地址：北京市大兴区亦庄镇荣华南路2号院大族广场T6号楼2202</span>
                        <img className='official-footer-logo' src={logoBottomImage} alt="" />
                        <div className='official-footer-wechat'>
                            <div className='official-footer-wechat-item'>
                                <img src={qr_code_2Image} alt="" />
                                <div>联系微信2</div>
                            </div>
                            <div className='official-footer-wechat-item'>
                                <img src={qr_code_1Image} alt="" />
                                <div>联系微信1</div>
                            </div>
                        </div>
                    </div>
                    <div className='official-footer-company'>Copyright © 2022 { window.location.host.indexOf("douzhuan.cn") > -1 ? '北京宏宇微网络技术有限公司' : window.location.host.indexOf("dmpdsp.com") > -1 ? '北京四翼鸟科技有限公司' : '' }</div>
                </footer>

                <Modal
                    centered
                    width={580}
                    visible={this.state.isSuccess}
                    footer={null}
                    onCancel={() => { this.setState({ isSuccess: false }) }}
                >
                    <div className='official-dialog'>
                        <img src={notificationSuccessImage} alt="" />
                        <div className='official-dialog-content'>预约成功</div>
                        <div className='official-dialog-subTitle'>请保持电话畅通，我们的工作人员将在0-2个工作日内和您取得联系</div>
                        <button className='official-dialog-btn' onClick={() => { this.setState({ isSuccess: false }) }}>我知道了</button>
                    </div>
                </Modal>

                <Modal
                    centered
                    width={400}
                    visible={this.state.isFail}
                    footer={null}
                    onCancel={() => { this.setState({ isFail: false }) }}
                >
                    <div className='official-dialog'>
                        <img src={notificationFailImage} alt="" />
                        <div className='official-dialog-content'>提交失败</div>
                        <div className='official-dialog-subTitle'>网络不佳，请稍后再试</div>
                        <button className='official-dialog-btn' onClick={() => { this.setState({ isFail: false }) }}>我知道了</button>
                    </div>
                </Modal>

                <Drawer
                    placement="top"
                    closable={false}
                    maskClosable
                    visible={this.state.isShowMenu}
                >
                    <div className='official-drawer'>
                        <div className='official-drawer-title'>
                            <div className='official-drawer-logo'></div>
                            <img className='official-drawer-close' onClick={()=> { this.setState({isShowMenu: false}) }} src={ closeWapImage } alt="" />
                        </div>
                        <div className='official-drawer-tabbarList'>
                            {
                                tabbarList.map((tabbarItem, tabbarKey) => {
                                    return <a href={ tabbarItem.warpHerf } className={ `${this.state.activeTabbar === tabbarKey ? 'official-drawer-tabbarItem-active' : ''} official-drawer-tabbarItem` } key={tabbarKey} onClick={ ()=> { this.handleDrawerClick(tabbarItem, tabbarKey) } }>{ tabbarItem.label }</a>
                                })
                            }
                        </div>
                    </div>
                </Drawer>
            </div>
        )
    }
}